import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.display},[_c(VDialog,{attrs:{"max-width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v(" Need a free document scanner? "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('p',[_vm._v(" Use your smartphone to scan any document, like a "+_vm._s(_vm.doc)+", save it on your computer or email it to yourself, and then you can store it in your secure Peeps File archive along with saving your "+_vm._s(_vm.docNumber)+". ")]),_c('div',{staticClass:"d-flex justify-center my-3"},[_c('div',[_c('a',{attrs:{"href":"https://swiftscan.app","target":"_blank"}},[_c(VImg,{staticClass:"cursor-pointer",attrs:{"max-width":"50","src":require("@/assets/img/SwiftScan.png")}})],1)]),_c('div',{staticStyle:{"max-width":"250px","margin-left":"1rem"}},[_vm._v(" Click this scanner icon to learn more about this free scanning app ")])]),_c('div',{staticClass:"d-flex"},[_c('div',[_c(VImg,{attrs:{"src":require("@/assets/img/PeepsBeside.svg"),"max-width":"35"}})],1),_c('div',[_c('p',{staticClass:"peeps--font ml-1"},[_vm._v(" Note: there are other scanning apps and alternatives on the market, but my technical team has had good success with this app, and thought sharing that with you might be helpful. If you learn of something better, please let me know by posting a Comment. ")])])])])],1)],1),_c('div',{},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"text-capitalize",attrs:{"icon":_vm.icon,"dark":"","large":"","color":"red"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},on),[(!_vm.icon)?_c('span',{staticClass:"mr-2"},[_vm._v(" Scanner ")]):_vm._e(),_c(VIcon,[_vm._v(" mdi-scan-helper "),_vm._t("iconOption")],2)],1)]}}],null,true)},[_c('span',[_vm._v("Free app for easy scanning of paper documents")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }